<template>
  <div>
    <div class="flix-panel" :class="[{'flix-panel-success': membership == 'free'}, {'flix-panel-info': membership == 'premium'}]">
      <div class="flix-panel-heading flix-text-center">
        <h2 class="flix-html-h2" style="margin-bottom: 0px"><flixIcon :id="'gift'" /> Free</h2>
        {{ $t('message.membership') }}
      </div>
      <div class="flix-panel-body" style="padding: 0">
        <div class="flix-list-group" >
          <a href="https://web.bookingflix.com/prices" target="_blank" class="flix-list-group-item flix-html-a">
            <flixIcon class="icon flix-text-info" :id="'info-sign'"/>
            {{ $tc('message.function', 2) }} &amp; {{ $tc('message.price', 2) }}
            <div class="flix-clearfix" />
          </a>
          <div class="flix-list-group-item flix-text-center">
            <h3 class="flix-html-h2" style="margin: 0">Kostenfrei</h3>
          </div>
          <div class="flix-list-group-item flix-active flix-text-center" v-if="membership === 'free'">
            {{ $t('message.active') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    membership: String
  },
  data () {
    return {
      includes: [
        'Basis-Funktionen von Terminflix nutzbar',
        'Termin maximal 12 Tage im Voraus buchbar für Kunden',
        'kein persönlicher Support'
      ]
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .icon
    font-size: 2em
    margin-right: 8px
    display: block
    float: left
    margin-bottom: 10px
</style>
